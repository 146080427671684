<template>
<div class="sidemenu">
<!-- <SidebarBuilder :menu_item="'Join our blog newsletter!'">
   <form class="form" @submit.prevent="submitForm">
       <div class="input-group">
        <input
          name="email"
          id="email"
          v-model.trim="email"
          placeholder=" "
          type="email"
          autocomplete="on"
          class="input-group__input"
          @change="v$.email.$validate()"
          :class="[{ error: v$.email.$error }, { success: email.length }]"
        />
        <label class="placeholder" :class="{ error: v$.email.$error }"
          >Email Address<span>*</span></label
        >
      </div>
      <span class="error" v-if="v$.email.$error">
        Error: email entered incorrectly
      </span>
      <button class="button primary large send" type="submit" :class="[isFormSubmissionInProgress?'loading':'']" :disabled="isFormSubmissionInProgress">
        {{isFormSubmissionInProgress?'Sending':'Send'}}
        <span v-if="!isFormSubmissionInProgress" class="icon"
          ><img
            v-svg-inline
            :data-src="require('../../assets/icons/rarr-icon.svg')"
            alt=""
        /></span>
      </button>
   </form>

</SidebarBuilder> -->
<!-- <SidebarBuilder :menu_item="'Search'">
       <div class="input-group search">
        <input
          name="search"
          id="search"
          v-model.trim="search"
          placeholder=" "
          type="text"
          class="input-group__input"
          @change="showResults"
        />
        <label class="placeholder"
          >Find some...</label
        >
      <img
        v-svg-inline
        class="search-icon"
        :data-src="require('@/assets/icons/search-icon.svg')"
        alt=""
      />
      </div>
</SidebarBuilder> -->
<!-- <SidebarBuilder :menu_item="'Categories'">
       <ul>
           <li>Innovations (54)</li>
           <li>Process (23)</li>
           <li>Customer focus (21)</li>
           <li>Analytics (10)</li>
           <li>3rd party widgets (9)</li>
           <li>Touch Panel Control (4)</li>

      </ul>
</SidebarBuilder> -->
<SidebarBuilder :menu_item="'Feature posts'" class="blog__feature-posts">
       <ul>
         <li v-for="post in sidebar_content.find(content => content.id === 'posts').content"
      :key="post">
      <router-link :to="post.link">
        {{post.title}}
      </router-link>
    </li>
      </ul>
</SidebarBuilder>
<SidebarBuilder :menu_item="'Tags'" class="blog__tags">
     <div
       v-for="tag in tags"
      :key="tag"
      @click="searchByTag(tag)"
      class="blog__tags__tag"
      :class="[activeTag===tag || activeTags.includes(tag) ? 'active-tag':'']"
    >
      {{tag}}
    </div>
</SidebarBuilder>
</div>
</template>
<script>
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
// import axios from "axios";
import { mapState } from "vuex";
import SidebarBuilder from './SidebarBuilder.vue';
export default {
  components:{
    SidebarBuilder
  },
  data() {
    return {
        v$: useValidate(),
        email:'',
        search:'',
        activeTags:[],
        activeTag:'',
        isFormSubmissionInProgress: false,
        sidebar_content:[
            {
                id:'email',
                title:'Join our blog newsletter!',
            },
            {
                id:'search',
                title:'Search'
            },
            {
                id:'categories',
                title:'Categories',
                content:['Innovations','Process','Customer focus','Analytics','3rd party widgets','Touch Panel Control'],
                counts:true
            },
            {
                id:'posts',
                title:'Feature posts',
                content:[
                  {
                    title:'Our successful story',
                    link:'/our-story'

                  },
                   {
                    title:'Cant’ find a vendor for your project',
                    link:'/cant-find'
                  },
                   {
                    title:'Can’t consider outsourcing',
                    link:'/consider-outsourcing'
                  },
                    {
                        title: 'Hiring with Outsourcing Company vs Recruiting Agency',
                        link: '/blog/outsourcing-company-vs-recruiting-agency'
                    },
                ]
            }
        ]
    }
  },
    validations() {
    return {
      email: { required, email },
    }
  },
  methods:{
    searchByTag(tag){
      if (this.$route.path === '/blog'){
        if(!this.activeTags.length || !this.activeTags.includes(tag)){
          this.activeTags.push(tag)
          this.$emit('searchByTags',this.activeTags)
        }
        else if (this.activeTags.includes(tag)) {
          this.activeTags.splice(this.activeTags.indexOf(tag),1)
          this.$emit('searchByTags',this.activeTags)
        }
      } else {
      if(!this.activeTag.length){
        this.activeTag = tag
        this.$emit('searchByTag',this.activeTag)
      }else{
        if(this.activeTag === tag){
          this.activeTag = ''
          this.$emit('searchByTag',this.activeTag)
        }
      }
    }
    },
    //     submitForm() {
    //   this.v$.$validate();
    //   if (!this.v$.$error) {
    //     const messageFromUser = {
    //       subject:'Join our blog newsletter!',
    //       email: this.email,
    //     };
    //     // console.log(messageFromUser);
    //     this.isFormSubmissionInProgress = true;
    //     axios.post("/backend/contact.php", messageFromUser, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data'
    //       }
    //     })
    //     .then((resp) => {
    //       // handle success
    //       const isSuccess = resp?.data?.success || false;
    //       this.$emit("onFormSubmission", {
    //         isSent: true,
    //         hasError: !isSuccess,
    //         messageText: resp?.data?.message || 'Your request was successfully submitted.'
    //       });
    //     })
    //     .catch((err) => {
    //       // handle error
    //       this.$emit("onFormSubmission", {
    //           isSent: true,
    //           hasError: true,
    //           messageText: err?.response?.messageText || 'An internal error has occurred. Please try again later.'
    //       });
    //     })
    //     .then(() => {
    //       // always executed
    //       this.isFormSubmissionInProgress = false;
    //     });
    //   }
    //   return;
    // },
  },
  props:{
    tagFromPost:Array
  },
    computed: {
        ...mapState(['blog']),
        tags() {
            let content = []
            if (!this.tagFromPost || !this.tagFromPost.length) {
                this.blog.forEach(item => item.tags.forEach(tag => content.push(tag)))
            } else {
                let newContent = []
                this.blog.forEach(item => item.tags.forEach(tag => newContent.push(tag)))
                this.tagFromPost.forEach(i => newContent.splice(newContent.indexOf(i), 1))
                content = newContent
            }
            content = [...new Set(content)]
            return content
        }
    }
}
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.sidemenu{
  .input-group__input{
    background: $white;
  }
}
.search .placeholder{
    top:-5px;
}
.search-icon{
  position:absolute;
  top:-2px;
  right: 30px;
}
.blog__tags{
  .sidebar__content{
    display:flex;
    flex-wrap: wrap;
  }
  &__tag{
    margin:0 5px 5px 0;
    padding:6px 12px;
    color:$red;
    background: $white;
    border-radius: 26px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    border:1px solid $red;
    cursor:pointer;
    @include media(">=tablet") {
      font-size: 16px;
      line-height: 28px;
      padding:10px 24px;
      margin:0 20px 20px 0;

    }
    position: relative;
    display: inline-block;
    background: $white;
    background-image: linear-gradient($darkenRed, $darkenRed);
    background-repeat: no-repeat;
    transition: background-size .3s, color .3s;
    background-size: 0% 100%;
      &:hover{
        background-size: 100% 100%;
        color:$white;
      }
  }
}
.active-tag{
  background-image: linear-gradient($red, $red);
  background-size: 100% 100%;
  color:$white;
}
.blog__feature-posts{
  ul{
    list-style-type:'> ';
    margin: 0;
    padding-left:10px;
    opacity:1;
    @include media(">=tablet") {
      padding-left:20px;
    }
    li{
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      cursor:pointer;
      a{
        color:$black;
        text-decoration: none;
         transition:all 0.3s;
        &:hover{
          color:$red;
        }
      }
      margin-bottom:5px;
       @include media(">=tablet") {
         margin-bottom:10px;
         font-size: 20px;
      line-height: 32px;
       }
    }
  }
}
</style>
