<template>
  <div
      class="breadcrumbs"
      :class="{ 'breadcrumbs--scroll': scroll }"
  >
    <div class="breadcrumbs__container">
      <ol class="breadcrumbs__breadcrumb">
        <template v-for="item in breadcrumbs" :key="item.name">
          <li
              v-if="item.link && item.name"
              class="breadcrumbs__breadcrumb__item link__item"
          >
            <router-link :to="item.link">
              {{ item.name }}
            </router-link>
          </li>
          <li
              v-if="!item.link && item.name === 'Blog'"
              class="breadcrumbs__breadcrumb__item link__item"
          >
            <a href="#" @click="goBack()">{{ item.name }}</a>
          </li>
          <li
              v-if="!item.link && item.name !== 'Blog'"
              class="breadcrumbs__breadcrumb__item"
          >
            {{ item.name }}
          </li>
        </template>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      default: () => { return [] }
    },
    scroll: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    goBack(){
      this.$emit('goBack')
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.breadcrumbs {
  $block-name: &;
  position: fixed;
  z-index: 1;
  top: 60px;
  left: 0;
  width: 100%;
  background: $white;
  transition: all 0.3s ease;
  &__container {
    max-width: 1920px;
    width: 100%;
    padding: 0 16px;
    @include media("<phone") {
      margin: 10px 0;
    }
    @include media(">=tablet") {
      padding: 0 40px;
    }
    @include media(">=widescreen") {
      margin: 0 auto;
      padding: 0 80px;
    }
  }
  &__breadcrumb {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-left: 0;
    list-style: none;
    transition: all 0.3s ease;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      border: none;
      background-color: inherit;
    }
    &::-webkit-scrollbar-track {
      border: none;
    }
    li {
      display: flex;
      min-width: max-content;
    }
    & > li {
      font-size: 12px;
      @include media("<=phone") {
        font-size: 11px;
      }
      @include media(">=tablet") {
        font-size: 16px;
      }
    }

    .link__item:after {
      content: "→";
      padding: 0 10px;
      font-size: 12px;
      color: $base-text-color;
      @include media("<=phone") {
        padding: 0 3px;
        font-size: 8px;
      }
      @include media(">=tablet") {
        font-size: 16px;
      }
    }
    &__item > a {
      font-size: 12px;
      text-decoration: none;
      color: $base-text-color;
      transition: all 0.3s;
      @include media("<=phone") {
        font-size: 11px;
      }
      @include media(">=tablet") {
        font-size: 16px;
      }
    }
    &:hover {
      .breadcrumbs__breadcrumb__item {
        & a {
          opacity: 0.5;
          &:hover {
            opacity: 1;
            color: $red;
          }
        }
      }
    }
  }
  &--scroll {
    border-bottom: 1px solid $red;
    #{$block-name} {
      &__breadcrumb {
        margin: 10px 0;
      }
    }
  }
}
</style>