<template>
  <div class="sidebar" :class="[menu_item? `${menu_item.toLowerCase()}`:'']">
    <div class="sidebar__title">{{menu_item}}</div>
    <div class="sidebar__content">
        <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name:'SidebarBuilder',
  data() {
    return {

    }
  },
  props:{
      menu_item: String,
  }
}
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.sidebar{
    background: #F0F0F0;
    border-radius: 30px;
    margin-bottom:20px;
    padding: 20px;
    color:#232323;
     @include media(">=tablet-xl") {
       margin-bottom:80px;
       padding: 40px;
       margin-bottom:40px;
     }
    &__title{
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      padding-bottom:20px;
      @include media(">=tablet-xl") {
        font-size: 40px;
        line-height: 48px;
        padding-bottom:40px;
      }
    }
}
</style>